import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import InsideHeader from '../components/insideheader'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'

export default ({ data }) => {
    console.log(data);
    const page = data.dataJson
    return (
        <>
            <Helmet title={page.title}>
                <meta name='description' content={page.meta.description} />
                <meta name='keywords' content={page.meta.keywords} />
                <meta property='og:title' content={page.title} />
                <meta property='og:description' content={page.meta.description} />
                <meta name='twitter:card' content='summary_large_image' />
            </Helmet>
            <Layout>
                <InsideHeader pageTitle={page.title} pageSubTitle={page.subTitle} pageSlug={page.slug} headerImage={page.titleBackground} />
                <main className="main-content">
                    <section className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 mx-auto">
                                    {page.contents.map((item, index) => (
                                        <>
                                            {item.imageLeft && item.image &&
                                                <div className="col-md-5 mx-auto text-center mt-8 mt-md-0 float-left">
                                                    <img src={item.image} alt={page.title} className="rounded-lg border shadow float-left" width="250" height="250"/>
                                                </div>
                                            }
                                            {!item.imageLeft && item.image &&
                                                <div className="col-md-5 mx-auto text-center mt-8 mt-md-0 float-right">
                                                    <img src={item.image} alt={page.title} className="rounded-lg border shadow float-right"  width="250" height="250"/>
                                                </div>
                                            }
                                            <ReactMarkdown source={item.heading} escapeHtml={false} />
                                            <ReactMarkdown source={item.content} escapeHtml={false} />
                                        </>
                                    ))}
                                    <br />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
        title
        subTitle
        titleBackground
        contents {
           heading
           image
           content
         }
        meta {
            description
    		keywords
    		og_title
    		og_description
    		og_image
    		og_url
        }
    }
  }
`
